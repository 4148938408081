import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_u = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - U'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>U</h2>
     <LatQuotesIntro />
    
     <p><b>Uberrimae fidei</b> - Of the utmost good faith</p>
<p><b>Ubi amor, ibi oculus</b> - Where love is, there is insight</p>
<p><b>Ubi bene, ibi patria</b> - Where you feel good, there is your home</p>
<p><b>Ubi concordia, ibi victoria</b> - Where is the unity, there is the victory. (Publius Syrus)</p>
<p><b>Ubi dubium ibi libertas</b> - Where there is doubt, there is freedom</p>
<p><b>Ubi fumus, ibi ignis</b> - Where there's smoke, there's fire</p>
<p><b>Ubi maior, minor cessat</b> - The weak (minor) capitulates before the strong (major)</p>
<p><b>Ubi mel ibi apes</b> - Where honey, there bees, i.e., if you want support, you must offer something in return</p>
<p><b>Ubi revera (Ubi re vera)</b> - When, in reality</p>
<p><b>Ubi spiritus est cantus est</b> - Where there is spirit there is song</p>
<p><b>Ubi sunt?</b> - Where are they (the good old days)?</p>
<p><b>Ubi supra</b> - Where (cited) above</p>
<p><b>Ubicumque homo est, ibi benefici locus est</b> - Wherever there is a man, there is a place of/for kindness/service</p>
<p><b>Ubique</b> - Everywhere</p>
<p><b>Ultima ratio regum</b> - The final argument of kings</p>
<p><b>Ultima ratio regum</b> - The final argument of kings. (motto of Louis XIV on his cannon)</p>
<p><b>Ultima ratio</b> - Ultimate sanction</p>
<p><b>Ultima Thule</b> - The most distant Thule</p>
<p><b>Ultimo (ult.)</b> - Of the previous month</p>
<p><b>Ultimus Romanorum</b> - The last of the Romans</p>
<p><b>Ultra posse nemo obligatur</b> - No one is obligated beyond what he is able to do</p>
<p><b>Ultra vires</b> - Beyond [one's] authority outside the jurisdiction</p>
<p><b>Ultra vires</b> - Beyond the powers or legal authority</p>
<p><b>Un idea perplexi na</b> - The idea is strange to us</p>
<p><b>Una hirundo non facit ver</b> - One Swallow does not make Summer. (Horace)</p>
<p><b>Una salus victis nullam sperare salutem</b> - The one safety for the vanquished is to abandon hope of safety knowing there is no hope can give one the courage to fight and win</p>
<p><b>Una voce</b> - With one's voice</p>
<p><b>Unitam logica falsa tuam philosophiam totam suffodiant!</b> - May faulty logic undermine your entire philosophy!</p>
<p><b>Unitas mirabile vinculum</b> - The wonderful bond of unity</p>
<p><b>Unum necessarium</b> - The one necessary</p>
<p><b>Unus multorum</b> - One of many. (Horace)</p>
<p><b>Urbanus et instructus</b> - A gentleman and a scholar</p>
<p><b>Urbem lateritiam invenit, marmoream reliquit</b> - He found a city [Rome] of bricks and left a city of marble. (Augustus)</p>
<p><b>Urbi et orbi</b> - To the city [Rome] and to the world</p>
<p><b>Urbi et orbi</b> - To the city and to the world. (preface of Papal documents)</p>
<p><b>Ut ameris, ama!</b> - To be loved, love!</p>
<p><b>Ut desint vires, tamen est laudanda voluntas</b> - Although the power is lacking, the will is commendable. (Ovid)</p>
<p><b>Ut dictum (ut dict.)</b> - As directed</p>
<p><b>Ut humiliter opinor</b> - In my humble opinion</p>
<p><b>Ut incepit fidelis sic permanet</b> - As loyal as she began, so she remains</p>
<p><b>Ut infra</b> - As below</p>
<p><b>Ut sementem feceris, ita metes</b> - As you sow, so shall you reap. (Cicero)</p>
<p><b>Ut si!</b> - As if!</p>
<p><b>Ut sit magna, tamen certe lenta ira deorum est</b> - The wrath of the gods may be great, but it certainly is slow</p>
<p><b>Ut supra (ut sup.)</b> - As above</p>
<p><b>Uti foro</b> - To play the market</p>
<p><b>Uti possidetis</b> - As you possess</p>
<p><b>Uti, non abuti</b> - To use, not abuse</p>
<p><b>Utile et dulce</b> - Useful and pleasant</p>

   </Layout>
  )
}

export default LatQuotes_u
